<template>
  <div>
    <el-dialog :title="text+'讲解'"
               :visible.sync="dialogVisible"
               :close-on-click-modal="false"
               :append-to-body="true"
               width="1200rem"
               :before-close="handleClose">
      <el-table :data="tableData"
                style="width: 100%">
        <el-table-column :prop="filename"
                         align="left"
                         label="视频名称" />
        <el-table-column width="200"
                         align="center"
                         label="操作">
          <template slot-scope="{row}">
            <el-button type="primary"
                       @click="showVideo(row)">
              视频讲解
            </el-button>
          </template>
        </el-table-column>
      </el-table>
    </el-dialog>
  </div>
</template>
<script>

import { videoList } from '@/api/knowledgeVideo.js'
import { checkpointVideo, knowledgeVideo } from '@/api/checkpoint.js'
export default {
  data () {
    return {
      form: {},
      tableData: [],
      dialogVisible: false,
      text: '',
      urlname: '',
      filename: ''
    }
  },
  methods: {
    handleClose () {
      this.dialogVisible = false
    },
    showVideo (row) {
      window.open(row[this.urlname], '_blank')
    },
    async initList (params, edu_grade_id) {
      if (edu_grade_id == 1) {
        let { data } = await checkpointVideo(params)
        this.urlname = 'video_link'
        this.filename = 'teaching_video_name'
        this.tableData = data.list
      } else {
        this.urlname = 'hash_id'
        this.filename = 'filename'
        let { data } = await knowledgeVideo(params)
        this.tableData = data.list
      }

    }
    // async save () {
    //   await collect(this.form)
    //   this.handleClose()
    //   this.$message.success('收藏成功，可前往错题本查看')
    // }
  }
}
</script>

<style lang="scss" scoped>
::v-deep .el-dialog__header {
  font-size: 20rem;
  font-weight: bold;
  color: black;
  text-align: center;
}

::v-deep .el-dialog__headerbtn .el-dialog__close {
  color: white;
  background: #cccccc;
  border-radius: 50%;
  padding: 4rem;
}

::v-deep .el-dialog {
  border-radius: 20rem;
  height: 600rem;
  margin-top: 198rem !important;
}

::v-deep .el-table th,
.el-table tr {
  background: #f6f6f6;
}
::v-deep .cell {
  text-indent: 60rem;
}
</style>